import React from "react";
import { TContainerStateProps } from "./../../state/TContainerStateProps";
import { CRMTitleSection } from "../../components/CRMTitleSection/CRMTitleSection";
import { CRMSpacer } from "../../components/CRMSpacer/CRMSpacer";
import { CRMAutosaveIndicatorWrap } from "../../components/CRM/CRMAutosaveIndicatorWrap/CRMAutosaveIndicatorWrap";
import { CRMSpacingColumn } from "../../components/CRMSpacingColumn/CRMSpacingColumn";
import { CRMInputLabelAndErrorWrapComponent } from "../../components/CRMInputLabelAndErrorWrapComponent/CRMInputLabelAndErrorWrapComponent";
import { CRMDropdownComponent } from "../../components/CRMDropdownComponent/CRMDropdownComponent";
import { TStaffUser } from "../../../../domain/codecs/User";
import { CaseManagementToggles } from "../../../../domain/codecs/Cases";
import { CRMCodecEditForm } from "../../components/CRMCodecEditForm/CRMCodecEditForm";
import { CRMCaseRollbackComponent } from "../../components/CRMCaseRollbackComponent/CRMCaseRollbackComponent";
import { CRMButtonPrimary } from "../../components/CRMButtonPrimary/CRMButtonPrimary";

const userToString = (user: TStaffUser) => user.first_name && user.last_name ? `${user.first_name} ${user.last_name}`: user.email ?? ""

const CRMLegalCaseDetailsCaseManagementContainer = (props: TContainerStateProps): JSX.Element => {
    const isInstructed = props.state.forms.case_details_page.data.output.details.original.is_instructed !== null;

    const isAdmin = props.state.global.user.user_role === "admin";

    const isAuthoriser = props.state.global.user.user_role === "sail_legal_authoriser_staff";

    const isTitleCheck = props.state.forms.case_details_page.data.output.details.original.is_property_report === true;

    const formStatus = props.state.forms.case_details_page.data.output.details.status;

    return (
        <CRMAutosaveIndicatorWrap
            status={formStatus}
        >
            <CRMTitleSection>
                Case Management
            </CRMTitleSection>
            <CRMSpacer size="large" />
            <CRMSpacingColumn spacing="medium">
                {isInstructed &&
                    <>
                        {/* Case handler */}
                        <CRMInputLabelAndErrorWrapComponent
                            label={"Case Handler"}
                        >
                            <CRMDropdownComponent
                                disabled={!(isAdmin || isTitleCheck)}
                                value={props.state.forms.case_details_page.data.output.details.edited.case_handler_id ?
                                    props.state.forms.case_details_page.data.output.sail_legal_case_handlers.find(
                                        (user) => user.id === props.state.forms.case_details_page.data.output.details.edited.case_handler_id
                                    )?.id ?? ""
                                    : ""}
                                options={[
                                    { value: "", label: "Unknown" },
                                    ...props.state.forms.case_details_page.data.output.sail_legal_case_handlers.map(
                                        (user) => ({ value: user.id, label: userToString(user), isHidden: user.deleted_at ? true : false })
                                    )
                                ]}
                                onChange={(key) => props.dispatch({
                                    type: "LEGAL_CASE_DETAILS_CHANGE",
                                    payload: {
                                        ...props.state.forms.case_details_page.data.output.details,
                                        edited: {
                                            ...props.state.forms.case_details_page.data.output.details.edited,
                                            case_handler_id: key === "" ? null : key,
                                        }
                                    }
                                })}
                                displayError={false} />
                        </CRMInputLabelAndErrorWrapComponent>

                        <CRMInputLabelAndErrorWrapComponent
                            label={"Onboarding Manager"}
                        >
                            <CRMDropdownComponent
                                disabled={!(isAdmin || isTitleCheck)}
                                value={props.state.forms.case_details_page.data.output.details.edited.onboarding_manager_id ?
                                    props.state.forms.case_details_page.data.output.sail_legal_case_handlers.find(
                                        (user) => user.id === props.state.forms.case_details_page.data.output.details.edited.onboarding_manager_id
                                    )?.id ?? ""
                                    : ""}
                                options={[
                                    { value: "", label: "Unknown" },
                                    ...props.state.forms.case_details_page.data.output.sail_legal_case_handlers.map(
                                        (user) => ({ value: user.id, label: userToString(user), isHidden: user.deleted_at ? true : false })
                                    )
                                ]}
                                onChange={(key) => props.dispatch({
                                    type: "LEGAL_CASE_DETAILS_CHANGE",
                                    payload: {
                                        ...props.state.forms.case_details_page.data.output.details,
                                        edited: {
                                            ...props.state.forms.case_details_page.data.output.details.edited,
                                            onboarding_manager_id: key === "" ? null : key,
                                        }
                                    }
                                })}
                                displayError={false} />
                        </CRMInputLabelAndErrorWrapComponent>
                    </>
                }

                <CRMCodecEditForm
                    codec={CaseManagementToggles}
                    model={props.state.forms.case_details_page.data.output.details.edited}
                    validationErrors={props.state.forms.case_details_page.data.output.details.validationErrors}
                    columns={1}
                    onChange={(edited) => {
                        props.dispatch({
                            type: "LEGAL_CASE_DETAILS_CHANGE",
                            payload: {
                                ...props.state.forms.case_details_page.data.output.details,
                                edited: {
                                    ...props.state.forms.case_details_page.data.output.details.edited,
                                    ...edited,
                                }
                            }
                        });
                    }}
                    overrideComps={{
                        is_property_report: (props) =>
                            props.model === true
                                ? <div>
                                    <CRMButtonPrimary
                                        fullWidth={false}
                                        label="Upgrade To Quote"
                                        onClick={() => props.onChange(false)}
                                    />
                                </div>
                                : <></>
                    }}
                    hideFields={[
                        ...(props.state.forms.case_details_page.data.output.details.edited.is_property_report === false ? ["is_property_report" as "is_property_report"] : [])
                    ]}
                />

                {(isAdmin || isAuthoriser) && isInstructed &&
                    <CRMCaseRollbackComponent
                        rollbackTo="pre_instruction"
                        formStatus={formStatus}
                        onYes={() => {
                            props.dispatch({
                                type: "LEGAL_CASE_DETAILS_CHANGE",
                                payload: {
                                    ...props.state.forms.case_details_page.data.output.details,
                                    edited: {
                                        ...props.state.forms.case_details_page.data.output.details.edited,
                                        is_instructed: null,
                                        is_signed_off_by_authoriser_as_ready_for_exchange: null,
                                        signed_off_by_authoriser_as_ready_for_exchange_user: null,
                                    }
                                }
                            });
                        }} />
                }
            </CRMSpacingColumn>
        </CRMAutosaveIndicatorWrap>
    );
};

export default CRMLegalCaseDetailsCaseManagementContainer;